<template>
  <div class="main-page">
    <div class="main-header">
        <div class="logo">
          <img src="../../static/logo.png" alt="" />
        </div>
        <div class="link">
          <div>咨询与获取服务请致电</div>
          <div>17361281618 李经理</div>
        </div>
    </div>
    <div class="main-content">
      <div class="wy jw">
        <div class="logo logo-wy">
          <img src="../../static/wy-logo.png" alt="" />
        </div>
        <div class="title">晴朗社区+</div>
        <div class="title">物业管理系统</div>
        <div class="into" @click="intoWy">
          <div>进 入</div>
        </div>
      </div>
      <div class="jw">
          <div class="logo">
            <img src="../../static/jw-logo.png" alt="" />
          </div>
          <div class="title" style="margin-top: 20px">晴朗捷维</div>
          <div class="title" >特种设备维保管理系统</div>
          <div class="into" @click="intoJw">
            <div>进 入</div>
          </div>
      </div>
    </div>
    <div class="main-bottom">
      <div class="box1"><img src="../../static/box1.png" alt="" /></div>
      <div class="box2"><img src="../../static/box2.png" alt="" /></div>
      <div class="copyright">版权所有©copyright四川纷知软件开发有限公司丨蜀ICP备18026354号-6</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "index",
  methods:{
    //物业
    intoWy(){

      if (process.env.NODE_ENV == 'development') { //开发环境
        window.open('http://wuye.fenzhisoft.com/login', '_target')

      } else if (process.env.NODE_ENV == 'production') { //生产环境
        window.open('http://www.qinglang100.com/login', '_target')
      }

    },
    //捷维
    intoJw(){
      if (process.env.NODE_ENV == 'development') { //开发环境
        window.open('http://jw.fenzhisoft.com/login', '_target')

      } else if (process.env.NODE_ENV == 'production') { //生产环境
        window.open('http://jiewei.qinglang100.com/login', '_target')
      }
    }
  }
}
</script>

<style scoped>
.main-page{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  background-image: url(../../static/login-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.main-header{
  height: 63px;
  background: linear-gradient(90deg, rgba(140,132,228,0.50) -1%, rgba(126,218,229,0.50) 102%);
  display: flex;
  flex-direction: row;
  align-items: center;

}
.main-header .logo{
  margin-left: 20px;
  margin-top: 5px;
}
.main-header .link{
  border-left: 1px solid white;
    margin-left: 10px;
    padding-left: 10px;
  color: white;
  /*font-size: 12px;*/
}
.main-content{


  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 300px;
}
.main-content .wy{
  width: 240px;
  height: 294px;
  border-radius: 10px;
  background: #FFFFFF;

  box-shadow: 0px 4px 10px 0px rgba(138, 56, 245, 0.26);
}
.main-content .jw{
  width: 240px;
  height: 294px;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px 0px rgba(29, 159, 211, 0.36);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main-content .jw .logo-wy img{
  width: 118px;
  height: 102px;
}
.main-content .jw img{
  width: 72px;
  height: 88px;
}
.main-content .jw .title{
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.6);
}
.main-content .wy .into{

  background:  rgba(230, 161, 22, 0.26) !important;;
}
.main-content .wy .into div{

  background: #E6A116 !important;
}


.main-content .jw .into{
  margin-top: 35px;
  width: 111px;
  height: 43px;
  border-radius: 25px;
  opacity: 1;

  background: rgba(29, 159, 211, 0.21);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.main-content .jw .into div{
  width: 100px;
  height: 35px;
  border-radius: 20px;
  background: #1D9FD3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
}

.main-bottom{
  height: 83px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.main-bottom .copyright{
  margin-top: 30px;
  color: #3D3D3D;
  font-size: 12px;
}
.main-bottom .box1{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.main-bottom .box2{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

}
.main-bottom .box1 img,.main-bottom .box2 img{
  width: 100%;
  height: 100%;
}
</style>